import { render, staticRenderFns } from "./form-designer-con.vue?vue&type=template&id=4fee7cb7&scoped=true&"
import script from "./form-designer-con.vue?vue&type=script&lang=js&"
export * from "./form-designer-con.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fee7cb7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fee7cb7')) {
      api.createRecord('4fee7cb7', component.options)
    } else {
      api.reload('4fee7cb7', component.options)
    }
    module.hot.accept("./form-designer-con.vue?vue&type=template&id=4fee7cb7&scoped=true&", function () {
      api.rerender('4fee7cb7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/form-design/form-designer-con.vue"
export default component.exports