<template>
  <div style="width: 100%; height: 100%">
    <FormDesigner></FormDesigner>
  </div>
</template>

<script>
import FormDesigner from './form-designer.vue';

export default {
  //   @Prop() msg!: string;
  components: {
    FormDesigner
  }
};
</script>

<style scoped lang="scss">
</style>
